import React from "react";
import TopBarContext from "../../utils/TopBarContext";
import { connect } from "react-redux";
import Responsive from "../UI/Responsive/Responsive";
import TopBarDesktop, { InternalTopBarDesktop} from "./TopBarDesktop/TopBarDesktop";
import TopBarMobile, { InternalTopBarMobile } from "./TopBarMobile/TopBarMobile";
import TopBarDesktopNotLoggedIn from "./TopBarDesktop/TopBarDesktopNotLoggedIn"

function TopBar({ user }) {
  return (
    <TopBarContext.Consumer>
      {({ theme, isFullscreen }) => {
        return (
          <Responsive
            desktop={
              user.obj?.role === 'ADMIN'
                ? InternalTopBarDesktop
                : TopBarDesktopNotLoggedIn
            }
            mobile={
              user.obj?.role === 'ADMIN'
                ? InternalTopBarMobile
                : TopBarMobile
            }
            user={user}
            theme={theme}
            isFullscreen={isFullscreen}
          />
        );
      }}
    </TopBarContext.Consumer>
  );
}

export default connect(state => ({ user: state.user }))(TopBar);
