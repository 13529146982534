import React from "react";
import styles from "./TopBarMobile.module.scss";
import menuStyles from "../Menu.module.scss";
import { ReactComponent as HamburgerIcon } from "../../../images/HamburgerIconTransparent.svg";
import Drawer from "@material-ui/core/Drawer";
import withTopBar from "../withTopBar";
import classnames from "classnames";
import AuthLink from "../../AuthLink/AuthLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as QooeeLogo } from "../../../images/QooeeLogo.svg";
import TopBarMobileExhibitorList from "./TopBarMobileExhibitorList";

import { ReactComponent as UserAvatar } from "../../../images/UserIcon.svg";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/user/actions";
import { Link } from "react-router-dom";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { InternalSideBarDesktop } from "../../SideBar/SideBarDesktop/SideBarDesktop";

const useAvatarStyle = makeStyles(theme => ({
  root: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: "#e9e9e9"
  }
}));

const Hamburger = ({ onClick }) => (
  <span className={styles.Hamburger}>
    <HamburgerIcon onClick={onClick} />
  </span>
);

const NavBar = ({ open, setOpen, isFullscreen }) => {
  const hamburger = <Hamburger onClick={() => setOpen(!open)} />;
  if (isFullscreen === true)
    return <div className={styles.NavBarBase}>{hamburger}</div>;
  return (
    <div className={styles.NavBar}>
      {hamburger}
      <QooeeLogo className={styles.Logo} />
      <div className={styles.ExhOuter}>
        <TopBarMobileExhibitorList />
      </div>
    </div>
  );
};

const TopBarMobile = withTopBar(
  ({ isFullscreen, isLoginSuccess, user, topBarMenu }) => {
    const [open, setOpen] = React.useState(false),
      avatarStyle = useAvatarStyle(),
      dispatch = useDispatch();
    return (
      <>
        <NavBar isFullscreen={isFullscreen} open={open} setOpen={setOpen} />
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
          <div className={styles.ListOuter}>
            <InternalSideBarDesktop />
            {!isLoginSuccess && (
              <div
                className={styles.Logout}
              >
                <div><Link to={"/internal/login"}> <span><LibraryAddIcon/></span>  Login</Link></div>
              </div>
            )}
            {isLoginSuccess && (
              <div
                className={styles.Logout}
                onClick={() => {
                  dispatch(logout());
                  setOpen(false);
                }}
              >
                <span className="d-inline-block" style={{ width: "2rem" }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                log out
              </div>
            )}
          </div>
        </Drawer>
      </>
    );
  }
);

export default TopBarMobile;
// export default hot(TopBarMobile);

export const InternalTopBarMobile = withTopBar(
  ({ isFullscreen, isLoginSuccess, user, topBarMenu }) => {
    const [open, setOpen] = React.useState(false),
      avatarStyle = useAvatarStyle(),
      dispatch = useDispatch();
    return (
      <>
        <NavBar isFullscreen={isFullscreen} open={open} setOpen={setOpen} />
        <Drawer anchor={"left"} open={open} onClose={() => setOpen(false)}>
          <div className={styles.ListOuter}>
            <div className={styles.List}>
              {isLoginSuccess && (
                <div className={styles.User}>
                  <Avatar classes={avatarStyle}>
                    <UserAvatar />
                  </Avatar>
                  <div className={"ml-2"}>
                    <h5 className={"text-uppercase d-inline-block mb-1"}>
                      {user.first_name} {user.last_name}
                    </h5>
                    <span>{user.email}</span>
                  </div>
                </div>
              )}
            </div>
            <InternalSideBarDesktop />
            {isLoginSuccess && (
              <div
                className={styles.Logout}
                onClick={() => {
                  dispatch(logout());
                  setOpen(false);
                }}
              >
                <span className="d-inline-block" style={{ width: "2rem" }}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </span>
                log out
              </div>
            )}
          </div>
        </Drawer>
      </>
    );
  }
);
