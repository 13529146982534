import React, { Suspense } from "react";
import { createTheme } from "@material-ui/core/styles";
// import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import { withTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";

import variables from "../../styles/_variables.scss";
import { compose } from "../../utils/common";
import LoadingAnimationPage from "../UI/LoadingAnimationPage/LoadingAnimationPage";

export const TableMobile = compose(
)(
  ({
    title,
    data,
    columns,
    options,
    isLoading,
    ...props
  }) => {
    const getMuiTheme = () =>
    createTheme({
      typography: {
        // Tell Material-UI what's the font-size on the html element is.
        fontFamily: "Baloo 2, Arial",
        useNextVariants: true
      },
      overrides: {
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: "nowrap"
          },
          sortActive: {
            color: "white"
          }
        },
        MuiTableCell: {
          root: {
            zIndex: "30 !important",
            whiteSpace: "nowrap",
            paddingRight: "24px",
            border: "1px solid #b8b8b8",
            // borderBottom: `1px solid ${variables.internalBg}`
          },
          head: {
            zIndex: "30 !important",
            backgroundColor: "#3a3b3c !important",
            color: "white",
            "& .MuiSvgIcon-root": {
              fill: "white"
            }
          }
        }
      },
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: "#e4476b"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: "#0066ff",
          main: "#fff",
          // dark: will be calculated from palette.secondary.main,
          contrastText: "#000000"
        }
        // error: will use the default color
      }
    });
    const defaultOptions = {
      responsive: "scroll",
      // fixedHeader: false,
      // filter: false,
      rowsPerPageOptions: [10, 20, 50],
      rowsPerPage: 20,
      print: false,
      download: true,
      viewColumns: true,
      selectableRows: "multiple",
      searchOpen: true,
      elevation: 0
    };
   return (
    <>
      <div style={{ position: "relative" }}>
        {isLoading ? <LoadingAnimationPage loading={true} /> : null}
        <div>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={<h2>{title}</h2>}
              data={data}
              columns={columns}
              options={{  ...defaultOptions, ...options }}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </>
   )
})

// @withTranslation()
const Table = ({ title, data, columns, options, isLoading }) => {
  const getMuiTheme = () =>
    createTheme({
      typography: {
        // Tell Material-UI what's the font-size on the html element is.
        fontFamily: "Baloo 2, Arial",
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {    
          responsiveScroll: {
            maxHeight: "unset !important",
            "&::-webkit-scrollbar": {
              width: ".25rem"
            },
            "&::-webkit-scrollbar-thumb": {
              border: "2.5px solid #66666675"
              // border-radius: 10rem;
            },
            "&::-webkit-scrollbar-thumb:hover": {
              border: "2.5px solid #22222275"
            },
            "&::-webkit-scrollbar-track": {
              position: "absolute",
              right: "-3rem",
              top: "-50rem",
              background: "transparent"
            }
          }
        },
        MUIDataTableHeadCell: {
          root: {
            whiteSpace: "nowrap",
            padding: ".75rem .75rem"
          },
          sortActive: {
            // color: "white"
          }
        },
        MuiTableCell: {
          root: {
            zIndex: "30 !important",
            whiteSpace: "nowrap",
            // paddingRight: "24px",
            padding: ".75rem .75rem",
            // border: "1px solid #b8b8b8",
            borderBottom: `1px solid ${variables.internalBg}`
          },
          head: {
            zIndex: "30 !important",
            // backgroundColor: "#3a3b3c !important",
            // color: "white",
            "& .MuiSvgIcon-root": {
              fill: "white"
            }
          }
        }
      },
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: "#e4476b"
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          // light: "#0066ff",
          main: "#fff",
          // dark: will be calculated from palette.secondary.main,
          contrastText: "#000000"
        }
        // error: will use the default color
      }
    })

  const defaultOptions = {
    responsive: "scroll",
    // fixedHeader: false,
    // filter: false,
    rowsPerPageOptions: [10, 20, 50, 100, 200],
    rowsPerPage: 200,
    print: false,
    download: true,
    viewColumns: true,
    selectableRows: "multiple",
    searchOpen: true,
    elevation: 0
  }

  return (
    <div style={{ position: "relative" }}>
      {isLoading ? <LoadingAnimationPage loading={true} /> : null}
      <div>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={<h2>{title}</h2>}
            data={data}
            columns={columns}
            options={{ ...defaultOptions, ...options }}
          />
          {console.log(variables)}
        </MuiThemeProvider>
      </div>
    </div>
  )
}

export default Table;
